import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { trackRouter } from 'vue-gtag-next'
import _ from 'lodash'

const rootDefault = '/home'

const commissionDefault = {
    name: 'CommissionHistory'
}

const reportDefault = {
    name: 'ReportDetail',
    params: {
        mode: '1'
    }
}

const tiaInsuranceDefault = {
    name: 'TiaInsurance',
    params: {
        mode: '1'
    }
}

const tiaDefault = {
    name: 'TiaInsurance'
}

const tiaNotificationDefault = {
    name: 'TiaNotification',
    params: {
        mode: 'self'
    }
}

const routes = [
    {
        path: '/home',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../views/Home.vue')
            }
        ]
    },
    {
        path: '/tia/benefit',
        component: () => import('../layouts/FullSizeLayout.vue'),
        children: [
            {
                path: ':insNo/:id',
                name: 'TiaSupplierBenefit',
                meta: { permissions: [{ Tia: ['supplierSyncList'] }] },
                component: () => import('../views/tia/Benefit.vue'),
                beforeEnter: (to) => {
                    if (!to.params?.insNo || !to.params?.id) return tiaDefault
                }
            }
        ]
    },
    {
        path: '/tia/detail',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: ':id/:manCode?/:source?',
                name: 'TiaInsuranceDetail',
                component: () => import('../views/tia/InsuranceDetail.vue'),
                beforeEnter: (to) => {
                    if (!to.params?.id) return tiaDefault
                }
            }
        ]
    },
    {
        path: '/tia',
        name: 'Tia',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'insurance/:mode?',
                name: 'TiaInsurance',
                meta: {
                    pageMode: true,
                    isKeepAlive: true,
                    keepAliveFor: ['TiaInsuranceDetail', 'TiaCustomer'],
                    component: 'Insurance'
                },
                component: () => import('../views/tia/Insurance.vue'),
                beforeEnter: (to) => {
                    if (!['1', '2'].includes(to.params?.mode))
                        return tiaInsuranceDefault
                }
            },
            {
                path: 'notification/:mode?/:id?',
                name: 'TiaNotification',
                meta: {
                    keyEnable: true,
                    isKeepAlive: true,
                    keepAliveFor: ['TiaCustomer'],
                    component: 'Notification'
                },
                component: () => import('../views/tia/Notification.vue'),
                beforeEnter: (to) => {
                    if (!['self', 'org'].includes(to.params?.mode))
                        return tiaNotificationDefault
                }
            },
            {
                path: 'customer',
                name: 'TiaCustomer',
                meta: {
                    isKeepAlive: true,
                    keepAliveFor: ['TiaInsurance'],
                    component: 'Customer'
                },
                component: () => import('../views/tia/Customer.vue')
            },
            {
                path: '',
                beforeEnter: () => {
                    return tiaDefault
                }
            }
        ]
    },
    {
        path: '/report',
        name: 'Report',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'annual',
                name: 'ReportAnnual',
                component: () => import('../views/report/Annual.vue')
            },
            {
                path: 'detail/:mode?',
                name: 'ReportDetail',
                meta: {
                    pageMode: true
                },
                component: () => import('../views/report/Detail.vue'),
                beforeEnter: (to) => {
                    if (!['1', '2'].includes(to.params?.mode))
                        return reportDefault
                }
            },
            {
                path: 'daily',
                name: 'ReportDaily',
                component: () => import('../views/report/Daily.vue')
            },
            {
                path: 'contest',
                name: 'ReportContest',
                component: () => import('../views/report/Contest.vue')
            },
            {
                path: 'interval',
                name: 'ReportInterval',
                component: () => import('../views/report/Interval.vue')
            },
            {
                path: '',
                beforeEnter: () => {
                    return reportDefault
                }
            }
        ]
    },
    {
        path: '/performance',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: '',
                name: 'Performance',
                meta: {
                    isKeepAlive: true,
                    keepAliveFor: ['TiaCustomer'],
                    component: 'Performance'
                },
                component: () => import('../views/performance/Performance.vue')
            }
        ]
    },
    {
        path: '/organization',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: '',
                name: 'Organization',
                component: () => import('../views/manpower/Manpower.vue')
            }
        ]
    },
    {
        path: '/award',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: '',
                name: 'Award',
                meta: {
                    isKeepAlive: true,
                    keepAliveFor: ['TiaInsuranceDetail', 'TiaCustomer'],
                    component: 'Awards'
                },
                component: () => import('../views/award/Awards.vue')
            },
            {
                path: '/detail/:rule',
                name: 'AwardDetail',
                component: () => import('../views/award/AwardsDetail.vue')
            }
        ]
    },
    {
        path: '/product',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'receive',
                name: 'ProductReceive',
                component: () => import('../views/product/ProductReceive.vue')
            },
            {
                path: 'contract',
                name: 'ProductContract',
                component: () => import('../views/product/ProductContract.vue')
            },
            {
                path: 'summary',
                name: 'ProductSummary',
                component: () => import('../views/product/ProductSummary.vue')
            }
        ]
    },
    {
        path: '/commission',
        name: 'Commission',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'history',
                name: 'CommissionHistory',
                component: () => import('../views/commission/History.vue')
            },
            {
                path: 'underling',
                name: 'CommissionUnderling',
                component: () => import('../views/commission/Underling.vue')
            },
            {
                path: 'admin/:mType?',
                name: 'CommissionAdmin',
                component: () => import('../views/commission/Admin.vue'),
                beforeEnter: (to) => {
                    if (!['st', 'ls'].includes(to.params?.mType))
                        return commissionDefault
                } // HardCode 看能不能改掉
            },
            {
                path: '',
                beforeEnter: () => {
                    return rootDefault
                }
            }
        ]
    },
    {
        path: '/backstage',
        name: 'Backstage',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'product',
                name: 'BackstageProduct',
                component: () => import('../views/backstage/Product.vue')
            },
            {
                path: 'report/:mode',
                name: 'BackstageReport',
                meta: {
                    pageMode: true,
                    permissions: [
                        {
                            BackstageReportContest: ['reportInfos'],
                            BackstageReportPerformance: ['reportInfos'],
                            BackstageReportAwards: ['reportInfos']
                        }
                    ]
                },
                component: () => import('../views/backstage/Report.vue')
            }
        ]
    },
    {
        path: '/ctw',
        name: 'CTWBackstage',
        component: () => import('../layouts/NormalLayout.vue'),
        children: [
            {
                path: 'upload',
                name: 'CtwUpload',
                meta: {
                    isKeepAlive: true,
                    component: 'CtwUpload',
                    keepAliveFor: ['TiaInsuranceDetail', 'CtwCustomer']
                },
                component: () => import('../views/ctw/Upload.vue')
            },
            {
                path: 'customer',
                name: 'CtwCustomer',
                meta: {
                    isKeepAlive: true,
                    component: 'CtwCustomer',
                    keepAliveFor: ['CtwUpload']
                },
                component: () => import('../views/ctw/Customer.vue')
            },
            {
                path: 'event',
                name: 'CtwEvent',
                component: () => import('../views/ctw/Event.vue')
            }
        ]
    },
    {
        path: '/sso',
        // name: 'Sso',
        component: () => import('../layouts/FullSizeLayout.vue'),
        children: [
            {
                path: '',
                name: 'Sso',
                component: () => import('../views/Sso.vue')
            }
        ]
    },
    {
        path: '/',
        component: () => import('../layouts/WideSizeLayout.vue'),
        children: [
            {
                path: '',
                alias: ['/', '/index'],
                redirect: rootDefault
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import('../views/Login.vue'),
                beforeEnter: () => {
                    if (store.getters.isLogin) {
                        return rootDefault
                    }
                }
            },
            {
                path: 'sample',
                name: 'Sample',
                component: () => import('../views/Sample.vue')
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('../layouts/FullSizeLayout.vue'),
        children: [
            {
                path: '',
                name: 'NotFound',
                alias: ['NotFound', '404'],
                component: () => import('../views/NotFound.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // 刷新頁面畫面回到最上方
        return { top: 0 }
    }
})

router.beforeEach(async (to, from) => {
    await store.restored
    store.commit('set', {
        showErrorModal: false
    })

    const whiteListName = [
        'Login',
        'Sso',
        'NotFound',
        'CtwUpload',
        'CtwCustomer',
        'CtwEvent'
    ]
    const adminRoute = ['CommissionAdmin']
    const titleCAupRouter = ['ReportDetail', 'ReportDaily', 'ReportInterval']
    const enableRoute = process.env.VUE_APP_ENABLE_PAGE
        ? process.env.VUE_APP_ENABLE_PAGE.split(',')
        : []
    //admin頁面權限
    const permissionPages = Object.keys(store.state.permissions)
    // 若有 meta 中 permissions 的權限則允許進入頁面
    to.meta.permissions?.forEach((permission) => {
        Object.keys(permission).some((key) => {
            const hasPermission =
                _.intersection(store.state.permissions[key], permission[key])
                    .length > 0
            if (hasPermission) permissionPages.push(to.name)
            return hasPermission
        })
    })

    const toPermissionName = function () {
        if (to.name === 'CommissionAdmin') {
            return `${to.name}${to?.params?.mType === 'st' ? 'St' : 'Ls'}`
        } else return to.name
    }

    if (store.state.lastPagePath === '/home' && window.opener === null) {
        store.commit('set', { normalFilter: {} })
        store.commit('set', { advancedFilter: {} })
        store.commit('set', { lastPagePath: '' })
    }
    if (!from.name) {
        store.commit('set', { noLastPage: true })
    } else {
        store.commit('set', {
            noLastPage: false
        })
    }

    if (whiteListName.includes(to.name) || store.getters.isLogin) {
        if (
            // 不是admin還跑去admin頁面 -> 導去NotFound
            (adminRoute.includes(to.name) && !store.getters.isAdmin) ||
            // 跑去未開放功能頁面，也不是白名單頁面 -> 導去NotFound
            (!enableRoute.some((route) => to.path.includes(route)) &&
                !whiteListName.includes(to.name)) ||
            // 權限不足不能查看頁面，也不是白名單頁面 -> 導去NotFound
            (!permissionPages.includes(toPermissionName()) &&
                !whiteListName.includes(to.name) &&
                from.name !== 'Sso' &&
                to.name !== 'Home')
        ) {
            return { name: 'NotFound' }
        } else if (
            titleCAupRouter.includes(to.name) &&
            !store.getters.isAdmin &&
            !store.getters.isTitleCAup &&
            !store.getters.hasLeaderBgCodes
        ) {
            return { name: 'NotFound' }
        }
    } else {
        store.commit('set', {
            lastPagePath: to.path
        })
        return { name: 'Login' }
    }

    if (store.state.isSectionClicked) store.state.keepAliveIncludes = []
    if (!from.meta.keepAliveFor?.includes(to.name) && to.name !== from.name) {
        _.pull(store.state.keepAliveIncludes, from.meta.component)
    }
    store.state.isSectionClicked = false
})
router.afterEach((to) => {
    if (to.meta.isKeepAlive) {
        store.state.keepAliveIncludes.push(to.meta.component)
    }
})

/************************************
 * Gtag track Router
 ************************************/
trackRouter(router)

export default router
